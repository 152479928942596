export default {
	filters: {
		getLabel(key, data) {
			try {
				return data.find(v => v.value === key).label
			} catch(e) {}
		},
		ruleCondition(row) {
			const condition = row.ruleCondition || []
			const values = condition.map(({expressions}) => {
				const _operator = expressions[0].operator
				let t = ''
				expressions.forEach((v, index1) => {
					const {operator, symbolLeft, symbolRight, valueLeft, valueRight, variables} = v
					if (index1 > 0) {
						t += ` ${operator} `
					}
					if (symbolLeft && valueLeft) {
						t += `${valueLeft} ${symbolLeft}`
					}
					t += ` ${variables} `
					if (symbolRight && valueRight) {
						t += `${symbolRight} ${valueRight}`
					}
				})
				return {operator: _operator, t}
			})
			if (values.length > 1) {
				return values.reduce((lhs, rhs) => {
					const {operator, t} = rhs
					if (lhs === '') {
						return `(${t})`
					}
					return `${lhs} ${operator} (${t})`
				}, '')
			}
			return values[0].t
		}
	}
}