<template>
		<el-card>
			<advanced-search-bar>
				<template>
					<form-item-sites :abbr="true" :multiple="false" :local="true" v-model="queryForm.website" :parent="false" @onload="q.website=queryForm.website;searchEvent()" :clearable="false"></form-item-sites>
					<form-item>
						<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" @click="addItem">添加配置</el-button>
					</form-item>
				</template>
				<template #advanced>
					<form-item label="操作:">
						<el-select v-model="queryForm.operation" clearable size="mini">
							<el-option v-for="item in operators" :label="item.label" :value="item.value" :key="item.value"></el-option>
						</el-select>
					</form-item>
					<form-item label="操作级别:">
						<el-select v-model="queryForm.level" clearable size="mini">
							<el-option v-for="item in operatorLevels" :label="item.label" :value="item.value" :key="item.value"></el-option>
						</el-select>
					</form-item>
					<form-item label="配置状态:">
						<el-select v-model="queryForm.active" clearable size="mini">
							<el-option label="开启" :value="true"></el-option>
							<el-option label="关闭" :value="false"></el-option>
						</el-select>
					</form-item>
					<form-item v-if="false" label="Account ID:">
						<el-input v-model.trim="queryForm.accountId" size="mini"></el-input>
					</form-item>
					<form-item v-if="false" label="Campaign ID:">
						<el-input v-model.trim="queryForm.campaignId" size="mini"></el-input>
					</form-item>
				</template>
			</advanced-search-bar>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<el-table height="auto" :data="tableData" size="mini" class="bedget-switch-config-table">
				<el-table-column label="站点" prop="website" min-width="100">
				</el-table-column>
				<el-table-column label="操作" min-width="100">
					<template slot-scope="scope">
						{{scope.row.operation|getLabel(operators)}}
					</template>
				</el-table-column>
				<el-table-column label="操作级别" prop="level" min-width="100">
				</el-table-column>
				<el-table-column label="操作范围" min-width="200">
					<template slot-scope="scope">
						<div  v-if="scope.row.effectIds.length > 0">
							<table width="100%">
								<tr v-for="(effectId,index) in scope.row.effectIds" :key="index" class="d-flex flex-row">
									<td width="40%">{{effectId.level}}</td>
									<td width="60%">{{(effectId.ids || []).join(',')}}</td>
								</tr>
							</table>
						</div>
						<div v-if="scope.row.excludeIds.length > 0">
							<div>除以下之外</div>
							<table width="100%">
								<tr v-for="(excludeId,index) in scope.row.excludeIds" :key="index" class="d-flex flex-row">
									<td width="40%">{{excludeId.excludeLevel}}</td>
									<td width="60%">{{(excludeId.excludeIds || []).join(',')}}</td>
								</tr>
							</table>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="规则" min-width="150">
					<template slot-scope="scope">
						{{scope.row | ruleCondition}}
					</template>
				</el-table-column>
				<el-table-column label="执行时间" prop="cron" min-width="150">
				</el-table-column>
				<el-table-column label="配置人" prop="updatedBy.name" min-width="150">
				</el-table-column>
				<el-table-column label="配置更新时间" min-width="150">
					<template slot-scope="scope">
						{{scope.row.updatedAt|formatDate}}
					</template>
				</el-table-column>
				<el-table-column label="状态" min-width="150">
					<template slot-scope="scope">
						<el-switch
						  v-model="scope.row.active"
						  @change="updateStatus(scope.row)">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="150">
					<template slot-scope="scope">
						<el-button type="text" size="mini" @click="editRow(scope.row)">编辑</el-button>
						<el-button type="text" class="text-danger" size="mini" @click="delConfig(scope.row, scope.$index)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
			<el-dialog :visible.sync="visible.edit" width="1100px" :show-close="false" :before-close="handleClose" id="budget-switch-config-dialog">
				<div slot="title">
					<el-form ref="form" :model="form">
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item-sites :abbr="true" v-model="form.website" label-width="60px" prop="channel" size="mini"></el-form-item-sites>
							</el-col>
							<el-col :span="8">
								<el-form-item label="操作级别:" label-width="80px">
									<el-select v-model="form.switchLevel" :clearable="false" size="mini" class="w-100">
										<el-option v-for="item in operatorLevels" :label="item.label" :value="item.value" :key="item.value"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="操作:" label-width="60px">
									<el-select v-model="form.switchOperation" :clearable="false" size="mini" class="w-100">
										<el-option :class="{'d-none':item.value=='ARCHIVED'}" v-for="item in operators" :label="item.label" :value="item.value" :key="item.value"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
				</div>
				<div class="border-top border-bottom py-3">
					<table width="100%">
						<tr>
							<td>生效的范围:</td>
							<td>
								<div class="d-flex flex-row align-items-center">
									<span style="flex-shrink:0;display:inline-block;width:70px;">Account</span>
									<el-input :disabled="form.today" v-model.trim="form.accountId" size="mini" style="flex-shrink:1;"></el-input>
								</div>
							</td>
							<td>
								<div class="d-flex flex-row align-items-center">
									<span style="flex-shrink:0;display:inline-block;width:70px;">Campaign</span>
									<el-input :disabled="form.today" v-model.trim="form.campaignId" size="mini" style="flex-shrink:1;"></el-input>
								</div>
							</td>
							<td v-if="form.switchLevel == 'AD_SET' || form.switchLevel == 'AD'">
								<div class="d-flex flex-row align-items-center">
									<span style="flex-shrink:0;display:inline-block;width:70px;">AdSet</span>
									<el-input :disabled="form.today" v-model.trim="form.adSetId" size="mini" style="flex-shrink:1;"></el-input>
								</div>
							</td>
							<td>
								<el-checkbox v-model="form.today" size="mini">当日已调整内容</el-checkbox>
							</td>
						</tr>
						<tr>
							<td>排除的范围:</td>
							<td>
								<div class="d-flex flex-row align-items-center">
									<span style="flex-shrink:0;display:inline-block;width:70px;">Campaign</span>
									<el-input :disabled="form.today" v-model.trim="form.excludedCampaignId" size="mini" style="flex-shrink:1;"></el-input>
								</div>
							</td>
							<td v-if="form.switchLevel == 'AD_SET' || form.switchLevel == 'AD'">
								<div class="d-flex flex-row align-items-center">
									<span style="flex-shrink:0;display:inline-block;width:70px;">AdSet</span>
									<el-input :disabled="form.today" v-model.trim="form.excludedAdSetId" size="mini" style="flex-shrink:1;"></el-input>
								</div>
							</td>
						</tr>
					</table>
				</div>
				<div class="mt-3">
					<div class="d-flex flex-row align-items-center mb-2">
						<div style="width:80px;">调整频率: </div>
						<el-select v-model="form.freq" placeholder="请选择" size="mini">
							<el-option v-for="item in frequencies" :label="item.label" :value="item.value" :key="item.value"></el-option>
						</el-select>
					</div>
					<div v-if="form.freq == 'week'" class="d-flex flex-row">
						<div style="flex-shrink: 0;width:40px;" class="mr-2">周</div>
						<el-checkbox-group v-model="form.week" class="cron-checkbox">
						    <el-checkbox v-for="item in crontabWeek" :label="item.value">{{item.label}}</el-checkbox>
						</el-checkbox-group>
					</div>
					<div class="d-flex flex-row">
						<div style="flex-shrink: 0;width:40px;" class="mr-2">小时</div>
						<el-checkbox-group v-model="form.hours" class="cron-checkbox">
							<el-checkbox v-for="item in crontabHours" :label="item.value" :key="item.value">{{item.label}}</el-checkbox>
						</el-checkbox-group>
					</div>
					<div class="d-flex flex-row">
						<div style="flex-shrink: 0;width:40px;" class="mr-2">分钟</div>
						<el-checkbox-group v-model="form.minutes" class="cron-checkbox">
							<el-checkbox v-for="item in crontabMinutes" :label="item.value" :key="item.value">{{item.label}}</el-checkbox>
						</el-checkbox-group>
					</div>
				</div>
				<div class="border-top mt-2 pt-3">
					<div class="mb-2">生效规则:</div>
					<el-row v-for="(config, index0) in form.condition" :key="index0" :gutter="20">
						<el-col :span="3">
							<el-select v-model="config.operator" size="mini" :style="{'opacity': index0 === 0 ? 0:1}" :disabled="index0 === 0">
								<el-option label="AND" value="AND"></el-option>
								<el-option label="OR" value="OR"></el-option>
							</el-select>
						</el-col>
						<el-col :span="16">
							<div style="border:1px #ddd dashed;border-radius:5px;" class="mb-2">
								<div v-for="(condition, index1) in config.expressions" :key="`${index0}-${index1}`" class="d-flex flex-row align-items-center p-2">
									<el-select v-model="condition.operator" size="mini" style="width:80px;" :style="{'opacity': index1 === 0 ? 0:1}" :disabled="index1 === 0">
										<el-option label="AND" value="AND"></el-option>
										<el-option label="OR" value="OR"></el-option>
									</el-select>
									<el-input class="text-center mx-1" style="width:60px;" v-model.trim="condition.valueLeft" size="mini"></el-input>
									<el-select v-model="condition.symbolLeft" style="width:60px;" size="mini" placeholder=" ">
										<el-option label="" value=""></el-option>
										<el-option label="<" value="<"></el-option>
										<el-option label="<=" value="<="></el-option>
									</el-select>
									<el-select v-model="condition.variables" style="width:150px;" :clearable="false" size="mini" class="mx-1">
										<el-option v-for="item in variables" :label="item" :value="item" :key="item"></el-option>
									</el-select>
									<el-select v-model="condition.symbolRight" style="width:60px;" size="mini" placeholder=" ">
										<el-option label="" value=""></el-option>
										<el-option label="<" value="<"></el-option>
										<el-option label="<=" value="<="></el-option>
									</el-select>
									<el-input class="text-center mx-1" style="width:60px;" v-model.trim="condition.valueRight" size="mini"></el-input>
									<el-button v-if="config.expressions.length > 1" style="border:1px dashed;width:60px;" size="mini" @click="removeCondition(config.expressions, index1)">-删除</el-button>
									<el-button v-if="index1 === config.expressions.length-1" style="border:1px dashed;width:60px;" size="mini" @click="addCondition(config.expressions)">+添加</el-button>
								</div>
							</div>
						</el-col>
						<el-col :span="4">
							<div>
								<el-button class="mb-2" v-if="form.condition.length > 1" style="border:1px #ddd dashed;" size="mini" @click="removeConfig(index0)">-删除</el-button>
								<el-button v-if="index0 === form.condition.length-1" style="border:1px #ddd dashed;" size="mini" @click="addConfig">+添加</el-button>
							</div>
						</el-col>
					</el-row>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button size="mini" @click="visible.edit=false" >取 消</el-button>
					<el-button type="primary" size="mini" @click="saveAction">确 认</el-button>
				</div>
			</el-dialog>
		</el-card>
</template>

<script>
	import Common from '@/common/mixins/common.js'
	import {mapGetters} from 'vuex'
	import Page from '@/common/mixins/page.js'
	import Config from '../mixins/index.js'
	export default {
		mixins: [Common, Page, Config],
		inject: ['adminLayout'],
		data() {
			return {
				queryForm: {
					website: '',
					accountId: '',
					campaignId: '',
					level: '',
					operation: '',
					active: null
				},
				q: {},
				tableData: [],
				namespace: 'budget-switch',
				form: {},
				visible: {
					edit: false,
				},
				fetchOnCreated:false
			}
		},
		computed: {
			...mapGetters({
				operators: 'budget-switch/operators',
				operatorLevels: 'budget-switch/operatorLevels',
				crontabWeek: 'budget-switch/crontabWeek',
				frequencies: 'budget-switch/frequencies',
				crontabHours: 'budget-switch/crontabHours',
				crontabMinutes: 'budget-switch/crontabMinutes',
				variables: 'budget-switch/variables'
			})
		},
		methods: {
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				const payload = {
					params: {
						...this.q,
						...this.page
					},
					trigger
				}
				return this.$store.dispatch(`${this.namespace}/getConfigs`, payload)
			},
			handleList(content) {
				this.tableData = content.map(v => {
					var ruleCondition = [], effectIds = [], excludeIds = []
					try {
						ruleCondition = JSON.parse(v.ruleCondition) || []
					}catch(e) {}
					try {
						effectIds = JSON.parse(v.effectIds) || []
					} catch(e) {}
					try {
						excludeIds = JSON.parse(v.excludeIds) || []
					} catch(e) {}
					return {
						...v,
						ruleCondition,
						effectIds, 
						excludeIds
					}
				})
			},
			searchEvent() {
				this.q = {
					...this.queryForm
				}
				this.getList()
			},
			updateStatus(row, index) {
				this.adminLayout.showLoading()
				this.$store.dispatch(`${this.namespace}/activeAdSwitchConfig`, {...row})
				.then(res => {
					this.adminLayout.hideLoading()
				})
				.catch(err => {
					this.adminLayout.hideLoading()
					this.$showErrMsg(err)
				})
			},
			addItem() {
				this.form = {
					website: this.queryForm.website,
					switchLevel: '',
					switchOperation: '',
					accountId: '',
					campaignId: '',
					adSetId: '',
					excludedCampaignId: '',
					excludedAdSetId: '',
					freq: 'day',
					today: false,
					week: [],
					hours: [],
					minutes: [],
					condition: [{
						expressions: [{
							symbolLeft: '',
							operator: 'AND',
							valueLeft: '',
							variables: '',
							symbolRight: '',
							valueRight: ''
						}],
						operator: 'AND'
					}]
				}
				this.visible.edit = true
			},
			saveAction() {
				if (!this.form.website || !this.form.switchLevel || !this.form.switchOperation) {
					this.$showErrMsg('请完成配置选择')
					return
				}
				if (this.form.freq == 'week' && this.form.week.length === 0) {
					this.$showErrMsg('请完成调整频率设置')
					return
				} 
				if (this.form.hours.length === 0 || this.form.minutes.length === 0) {
					this.$showErrMsg('请完成调整频率设置')
					return
				}
				// range
				const effectLevel = this.form.today ? 'RESOURCE_CHANGED_TODAY' : this.form.switchLevel
				const effectIds = []
				const excludeIds = []
				const {accountId, campaignId, adSetId, excludedCampaignId, excludedAdSetId} = this.form
				const accountIds = accountId.match(/\d+/g)
				const campaignIds = campaignId.match(/\d+/g)
				const adSetIds = adSetId.match(/\d+/g)
				const excludedCampaignIds = excludedCampaignId.match(/\d+/g)
				const excludedAdSetIds = excludedAdSetId.match(/\d+/g)
				if (accountIds) {
					effectIds.push({
						level: 'ACCOUNT',
						ids: accountIds
					})
				}
				if (excludedCampaignIds) {
					excludeIds.push({
						level: 'CAMPAIGN',
						ids: excludedCampaignId
					})
				}
				if (campaignIds) {
					effectIds.push({
						level: 'CAMPAIGN',
						ids: campaignIds
					})
				}
				if (this.form.switchLevel !== 'CAMPAIGN') {
					if (adSetIds) {
						effectIds.push({
							level: 'AD_SET',
							ids: adSetIds
						})
					}
					if (excludedAdSetIds) {
						effectIds.push({
							level: 'AD_SET',
							ids: excludedAdSetIds
						})
					}
				}
				if (!this.form.today && effectIds.length === 0) {
					this.$showErrMsg('请配置生效的范围')
					return
				}
				// cron
				const week = this.form.freq == 'week' ? this.form.week.sort().map(v=>v+'').join(',') : '?'
				const hour = this.form.hours.join(',')
				const minute = this.form.minutes.join(',')
				const cron = `0 ${minute} ${hour} * * ${week}`
				// rules
				const condition = this.form.condition.map(({expressions, operator}) => {
					const v = expressions.map((k, i) => {
						if (i === 0) {
							return {
								...k,
								operator
							}
						}
						return {...k}
					})
					return {expressions: v}
				})
				const conditionValidator = ({symbolLeft, valueLeft, variables, symbolRight, valueRight}) => {
					return !variables || !(symbolLeft && valueLeft || symbolRight && valueRight)
				}
				const incompleted = condition.reduce((lhs, rhs) => {
					return lhs.concat(rhs.expressions)
				}, []).some(conditionValidator)
				if (incompleted) {
					this.$showErrMsg('生效规则存在错误')
					return
				}
				const body = {
					...this.form,
					cron,
					condition,
					effectLevel,
					effectIds,
					excludeIds
				}
				this.adminLayout.showLoading(true)
				this.$store.dispatch(`${this.namespace}/saveAdSwitchConfig`, body)
				.then(res => {
					this.adminLayout.hideLoading()
					this.visible.edit = false
					this.getList()
				})
				.catch(err => {
					this.adminLayout.hideLoading()
				})
			},
			addCondition(conditions) {
				conditions.push({
					symbolLeft: '',
					operator: 'AND',
					valueLeft: '',
					variables: '',
					symbolRight: '',
					valueRight: ''
				})
			},
			removeCondition(conditions, index) {
				conditions.splice(index, 1)
			},
			addConfig() {
				this.form.condition.push({
						expressions: [{
							symbolLeft: '',
							operator: 'AND',
							valueLeft: '',
							variables: '',
							symbolRight: '',
							valueRight: ''
						}],
						operator: 'AND'
					})
			},
			removeConfig(index) {
				this.form.condition.splice(index, 1)
			},
			delConfig(row, index) {
				this.$confirm('此操作将永久删除该配置, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.adminLayout.showLoading()
					this.$store.dispatch(`${this.namespace}/delAdSwitchConfig`, row.id)
					.then(res => {
						this.adminLayout.hideLoading()
						this.tableData.splice(index, 1)
					})
					.catch(err => {
						this.$showErrMsg(err)
						this.adminLayout.hideLoading()
					})
				})
			},
			editRow(row) {
				const data = {}
				const {effectIds, excludeIds, cron, ruleCondition, level} = row
				const cronList = cron.split(' ')
				const freq = cronList[5] === '?' ? 'day' : 'week'
				const week = cronList[5].match(/\d+/g) || []
				const hours = cronList[2].match(/\d+/g) || []
				const minutes = cronList[1].match(/\d+/g) || []
				try {
					effectIds.forEach(({level, ids}) => {
						if (level === 'ACCOUNT') {
							data['accountId'] = (ids || []).join(',')
						} else if (level === 'CAMPAIGN') {
							data['campaignId'] = (ids || []).join(',')
						} else if (level === 'AD_SET') {
							data['adSetId'] = (ids || []).join(',')
						}
					})
				} catch(e) {console.error(e)}
				try {
					excludeIds.forEach(({excludeLevel, excludeIds}) => {
						if (excludeLevel === 'CAMPAIGN') {
							data['excludedCampaignId'] = (excludeIds || []).join(',')
						} else if (excludeLevel === 'AD_SET') {
							data['excludedAdSetId'] = (excludeIds || []).join(',')
						}
					})
				} catch(e) {console.error(e)}
				const condition = ruleCondition.map(({expressions}) => {
					return {
						expressions,
						operator: expressions[0].operator
					}
				})
				this.form = {
					website: this.queryForm.website,
					switchLevel: '',
					switchOperation: '',
					accountId: '',
					campaignId: '',
					adSetId: '',
					excludedCampaignId: '',
					excludedAdSetId: '',
					freq: 'day',
					today: false,
					week: [],
					hours: [],
					minutes: [],
					condition: [{
						expressions: [{
							symbolLeft: '',
							operator: 'AND',
							valueLeft: '',
							variables: '',
							symbolRight: '',
							valueRight: ''
						}],
						operator: 'AND'
					}],
					...row,
					today: row.effectLevel === 'RESOURCE_CHANGED_TODAY',
					...data,
					week,
					freq,
					hours,
					minutes,
					condition,
					switchLevel: row.level,
					switchOperation: row.operation
				}
				this.visible.edit = true
			}
		}
	}
</script>
<style lang="scss">
	#budget-switch-config-dialog {
		.el-dialog__body {
			padding-top: 0px;
			table {
				border-collapse: separate;
				td {
					padding: 5px 5px;
				}
			}
		}
		.cron-checkbox {
			label.el-checkbox {
				width: 50px;
			}
		}
	}
	.el-table__cell {
		table tr {
			background-color: unset;
		}
	}
</style>

